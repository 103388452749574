import React from "react"
import { Link } from "gatsby"
import Layout from '../../components/layout'
import { StaticImage } from 'gatsby-plugin-image'




const TimeSliceCOKickOff = ({ data }) => {
  var aspect_ratio = 4 / 3

  return (
    <Layout pageTitle="INCUS | TIME-SLICE CO Kicks Off">
      <h1>TIME-SLICE CO Kicks Off</h1>

      <StaticImage
        alt="A picture of a student launching a sounding."
        src="../images/time-slice/soundings_co.png"
        layout="constrained"
        placeholder="blurred"
        objectPosition="0 0"
        aspectRatio={aspect_ratio}
        transformOptions={{ cropFocus: 'center' }}

      />

      <h5>June 14, 2024</h5>

      <p>Fort Collins, CO—On a warm late spring day in Northern Colorado, a radar groans as it rapidly turns from pointing at one storm to another. That radar is the Colorado State University C-band Hydrological Instrument for Volumetric Observation (CSU-CHIVO) radar at the CSU Agricultural Research, Development and Education Center facility near Fort Collins, CO. As storms develop and grow, the Multi-Sensor Agile Adaptive Sampling (MAAS) tracking algorithm developed by Stony Brook University directs CHIVO to rapidly sample storms as they move through the area, mimicking the rapid progression of the planned NASA INvestigation of Convective UpdraftS (INCUS) satellites. </p>

      <p>The CHIVO radar is just part of the <Link to="../../time-slice"><strong>T</strong>esting <strong>I</strong>NCUS <strong>M</strong>ethods <strong>E</strong>xperiment – <strong>S</strong>uborbital pre<strong>L</strong>aunch <strong>I</strong>nvestigations of <strong>C</strong>onvective <strong>E</strong>volution (TIME-SLICE) field campaign</Link>. A suite of instruments has been set up at the CSU Semi-arid Grasslands Research Center (SGRC) to measure convective cloud properties, rain amounts and types, local meteorological conditions, and more. These instruments at the SGRC, along with the scanning CHIVO radar, the National Weather Service NEXRAD radars at Cheyenne and Denver along with meteorological soundings launched by CSU are all part of a broader effort to understand the kinds of ground-based measurements needed to validate the INCUS satellite measurements.</p>

      <p>TIME-SLICE Colorado officially kicked off on May 15, 2024, with a storm passing nearly directly over the SGRC site, providing a unique opportunity for INCUS scientists to assess the rapid radar retrievals that will be produced by the forthcoming satellites. In addition to TIME-SLICE CO, future efforts centered around Norman, OK, and Huntsville, AL, are being developed to sample multiple environments. </p>

      Check out the data from TIME-SLICE CO <Link to="../../time-slice">here.</Link>



    </Layout >

  )
}

export default TimeSliceCOKickOff

