import { graphql, useStaticQuery } from "gatsby"

type UseNavigationProps = {
  allNavbarPage: {
    nodes: {
      name: string
      path: string
    }[]
  }
}

const useNavigation = () => {
  const data = useStaticQuery<UseNavigationProps>(graphql`
    query {
      allNavbarPage {
        nodes {
          name
          path
        }
      }
    }
  `)

  return data.allNavbarPage.nodes
}

export default useNavigation
